(function () {

    'use strict';

    var settings = {
        target: '.mod-roaming-stepper',
        callyaRoaming: '#roaming-stepper-callya-roaming',
        internationalCalling: '#roaming-stepper-international-calling'
    };

    var contractMode = $(settings.target).data('contract');

    var getCallyaRowLine = function (line) {
        var results = {};
        var lines = $(settings.callyaRoaming + ' table tr:nth-child(' + line + ') td');
        lines.each(function (key, value) {
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCallyaColLine = function (line) {
        var results = {};
        var lines = $(settings.callyaRoaming + ' table tr td:nth-child(' + line + ')');
        lines.each(function (key, value) {
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCallingRowLine = function (line) {
        var results = {};
        var lines = $(settings.internationalCalling + ' table tr:nth-child(' + line + ') td');
        lines.each(function (key, value) {
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCallingColLine = function (line) {
        var results = {};
        var lines = $(settings.internationalCalling + ' table tr td:nth-child(' + line + ')');
        lines.each(function (key, value) {
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCountryList = function (destination) {
        var countryRows = getRawCountryList(destination);
        var result = { countries: [] };

        for (var item in countryRows) {
            result.countries.push({ name: item, code: '' });
        }

        return result.countries;
    };

    var getRawCountryList = function (destination) {

        if ('inForeignCountries' === destination) {
            var countryRows = getCallyaColLine(2);

            var result = { countries: [] };
            $.each(countryRows, function (key, value) {
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });
        } else if ('toForeignCountries' === destination) {
            var countryRows = getCallingColLine(2);
            var result = { countries: [] };
            $.each(countryRows, function (key, value) {
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });
        } else {
            var countryRows = getCallyaColLine(2);

            var result = { countries: [] };
            $.each(countryRows, function (key, value) {
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });

            countryRows = getCallingColLine(2);

            $.each(countryRows, function (key, value) {
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });
        }

        return result.countries;
    };

    var isNotFreeEU = function (obj) {
        if (undefined !== obj.TableHeadline && 0 <= obj.TableHeadline.indexOf('CallYa EU-Roaming')) {
            return false;
        }
        return true;
    };

    var getButtonHTML = function (text, color, link) {
        return '<a href="' + link + '" target="_blank" rel="noopener noreferrer" class="btn btn-sml btn-' + color + '">' + text + '</a>';
    };

    var getFootnoteHTML = function (text) {
        return '<div class="mod mod-footnotes footnotes">' +
            '<div class="accordion">' +
            '<ul class="mod mod-toggle tg-accord">' +
            '<li class="tg-item">' +
            '<a href="#" class="tg-head tg-active" aria-expanded="true" id="aria-110360413400">' +
            '<svg class="i-xsml tg-hide"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-arrow-up-sml"></use></svg>' +
            '<svg class="i-xsml tg-show"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-arrow-down-sml"></use></svg>' +
            '</a>' +
            '<div class="tg-body tg-display" aria-labelledby="aria-110360413400" role="region">' +
            '<div class="tg-board">' +
            '<ol>' +
            '<li>' + text + '</li>' +
            '</ol>' +
            '</div>' +
            '</div>' +
            '</li>' +
            '</ul>' +
            '</div>' +
            '</div>';
    };

    var getTableHTML = function (valueObject) {
        var tableHeadline = valueObject.tableHeadline !== undefined ? valueObject.tableHeadline : '';
        var outgoingMobile = valueObject.outgoingMobile !== undefined ? valueObject.outgoingMobile + '/Min' : '';
        var outgoingHome = valueObject.outgoingHome !== undefined ? valueObject.outgoingHome + '/Min' : '';
        var incomingMobile = valueObject.incomingMobile !== undefined ? valueObject.incomingMobile + '/Min' : '';
        var incomingHome = valueObject.incomingHome !== undefined ? valueObject.incomingHome + '/Min' : '';
        var sms = valueObject.sms !== undefined ? valueObject.sms + '/SMS' : '';
        var mms = valueObject.mms !== undefined ? valueObject.mms + '/MMS' : '';
        var data = valueObject.data !== undefined ? valueObject.data + '/MB' : '';

        return '<div class="mod mod-responsive-table">' +
            '<div class="mod-responsive-table-options">' +
            '<div data-name="type" data-value="stacked" data-type="string"></div>' +
            '<div data-name="width" data-value="full" data-type="string"></div>' +
            '<div data-name="first-col-fixed" data-value="false" data-type="boolean"></div>' +
            '<div data-name="col-width-mode" data-value="auto" data-type="string"></div>' +
            '<div data-col-index="0" data-col-width="auto" data-type="string"></div>' +
            '<div data-col-index="1" data-col-width="auto" data-type="string"></div>' +
            '<div data-col-index="2" data-col-width="auto" data-type="string"></div>' +
            '<div data-col-index="3" data-col-width="auto" data-type="string"></div>' +
            '<div data-col-index="4" data-col-width="auto" data-type="string"></div>' +
            '<div data-name="first-line-headers" data-value="true" data-type="boolean"></div>' +
            '<div data-name="create-sub-headlines" data-value="true" data-type="boolean"></div>' +
            '<div data-name="group-by-first-col" data-value="false" data-type="boolean"></div>' +
            '<div data-name="join-rows-in-group" data-value="false" data-type="array"></div>' +
            '<div data-name="join-adjacent-cols" data-value="false" data-type="boolean"></div>' +
            '<div data-name="replace-with-icons" data-value="false" data-type="boolean"></div>' +
            '<div data-name="zebra" data-value="false" data-type="boolean"></div>' +
            '</div>' +
            '<table>' +
            '<tbody>' +
            '<tr>' +
            '<td>' + tableHeadline + '</td>' +
            '<td></td>' +
            '</tr>' +
            '<tr>' +
            '<td>Abgehende Anrufe Mobil (nach DE und in andere EU-Länder)</td>' +
            '<td>' + outgoingMobile + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Abgehende Anrufe Festnetz (nach DE und in andere EU-Länder)</td>' +
            '<td>' + outgoingHome + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Ankommende Anrufe Mobil</td>' +
            '<td>' + incomingMobile + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Ankommende Anrufe Festnetz</td>' +
            '<td>' + incomingHome + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>SMS (nach DE und in andere EU-Länder)</td>' +
            '<td>' + sms + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>MMS (nach DE und in andere EU-Länder)</td>' +
            '<td>' + mms + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Daten</td>' +
            '<td>' + data + '</td>' +
            '</tr>' +
            '<tbody>' +
            '</table>' +
            '<p>Preise inkl. MwSt.</p>' +
            '</div>' +
            '<div class="divide divide_sml divide_hidden">' +
            '<div></div>' +
            '</div>';
    };

    var getTextOnlyHTML = function (h3, copy) {
        var html = '<div class="text-only">' +
            '<h3>' + h3 + '</h3>' +
            '<p>' + copy + '</p>';
        html += '</div>' +
            '<div class="divide divide_sml divide_hidden">' +
            '<div></div>' +
            '</div>';

        return html;
    };

    var setInternationalCallingStructure = function () {
        var results = { toForeignCountries: {} };

        //var countryRows = getCallingColLine(2);
        var allRows = $(settings.internationalCalling + ' table tr');

        $.each(allRows, function (key) {
            // ignore the headlines
            if (2 < key) {
                var lineValue = getCallingRowLine(key);
                if (undefined !== lineValue[1]) {
                    if (!(lineValue[1] in results.toForeignCountries)) {
                        results.toForeignCountries[lineValue[1]] = {};
                    }
                    results.toForeignCountries[lineValue[1]][lineValue[2]] = {
                        EURoamingText: lineValue[3] !== undefined ? $.trim(lineValue[3]) : lineValue[3],
                        TableHeadline: lineValue[4] !== undefined ? $.trim(lineValue[4]) : lineValue[4],
                        OutgoingCallMobile: lineValue[5] !== undefined ? $.trim(lineValue[5]) : lineValue[5],
                        OutgoingCallHome: lineValue[6] !== undefined ? $.trim(lineValue[6]) : lineValue[6],
                        IncomingCallMobile: lineValue[7] !== undefined ? $.trim(lineValue[7]) : lineValue[7],
                        IncomingCallHome: lineValue[8] !== undefined ? $.trim(lineValue[8]) : lineValue[8],
                        Data: lineValue[11] !== undefined ? $.trim(lineValue[11]) : lineValue[11],
                        SMS: lineValue[9] !== undefined ? $.trim(lineValue[9]) : lineValue[9],
                        MMS: lineValue[10] !== undefined ? $.trim(lineValue[10]) : lineValue[10],
                        UpsellTariff: lineValue[12] !== undefined ? $.trim(lineValue[12]) : lineValue[12],
                        Footnote: lineValue[13] !== undefined ? $.trim(lineValue[13]) : lineValue[13],
                        Link: lineValue[14] !== undefined ? $.trim(lineValue[14]) : lineValue[14]
                    };
                }
            }
        });
        prepaid.toForeignCountries = results.toForeignCountries;
    };

    var setCallyaRoamingStructure = function () {
        var results = { inForeignCountries: {} };

        //var countryRows = getCallyaColLine(2);
        var allRows = $(settings.callyaRoaming + ' table tr');

        $.each(allRows, function (key) {
            // ignore the headlines
            if (2 < key) {
                var lineValue = getCallyaRowLine(key);
                if (undefined !== lineValue[1]) {
                    if (!(lineValue[1] in results.inForeignCountries)) {
                        results.inForeignCountries[lineValue[1]] = {};
                    }
                    var button = lineValue[13] !== undefined ? $.trim(lineValue[13]) : lineValue[13];
                    var buttonColor = 'em'; // grey button
                    if (undefined !== button && 0 <= button.indexOf('lila')) {
                        buttonColor = 'purple'; // purple button
                    }
                    var buttonText = 'Mehr Infos';
                    if (undefined !== button && 0 <= button.indexOf('Jetzt Tarifwechsel')) {
                        buttonText = 'Jetzt Tarifwechsel durchführen';
                    }
                    results.inForeignCountries[lineValue[1]][lineValue[2]] = {
                        EURoamingText: lineValue[3] !== undefined ? $.trim(lineValue[3]) : lineValue[3],
                        TableHeadline: lineValue[4] !== undefined ? $.trim(lineValue[4]) : lineValue[4],
                        OutgoingCallMobile: lineValue[5] !== undefined ? $.trim(lineValue[5]) : lineValue[5],
                        OutgoingCallHome: lineValue[6] !== undefined ? $.trim(lineValue[6]) : lineValue[6],
                        IncomingCallMobile: lineValue[7] !== undefined ? $.trim(lineValue[7]) : lineValue[7],
                        IncomingCallHome: lineValue[8] !== undefined ? $.trim(lineValue[8]) : lineValue[8],
                        Data: lineValue[9] !== undefined ? $.trim(lineValue[9]) : lineValue[9],
                        SMS: lineValue[10] !== undefined ? $.trim(lineValue[10]) : lineValue[10],
                        MMS: lineValue[11] !== undefined ? $.trim(lineValue[11]) : lineValue[11],
                        UpsellTariff: lineValue[12] !== undefined ? $.trim(lineValue[12]) : lineValue[12],
                        ButtonText: buttonText,
                        ButtonColor: buttonColor,
                        Link: lineValue[14] !== undefined ? $.trim(lineValue[14]) : lineValue[14]
                    };
                }
            }
        });
        prepaid.inForeignCountries = results.inForeignCountries;
    };

    var prepaid = { inForeignCountries: {}, toForeignCountries: {} };

    setCallyaRoamingStructure();
    setInternationalCallingStructure();

    /**
     *** The offer flow in a static format use select as belowgetJ
     *** offerFlow.toForeignCountries.Schweiz.other.option
     **/
    if ('prepaid' !== contractMode) {
        var countryGroups = {
            'inForeignCountries': {
                'Europe': [
                    'Azoren',
                    'Belgien',
                    'Bulgarien',
                    'Ceuta',
                    'D\u00E4nemark',
                    'Estland',
                    'Finnland',
                    'Frankreich',
                    'Franz\u00F6sisch Guayana',
                    'Gibraltar',
                    'Griechenland',
                    'Gro\u00DFbritannien',
                    'Guadeloupe',
                    'Irland',
                    'Island',
                    'Italien',
                    'Kanarische Inseln',
                    'Kroatien',
                    'La Reunion',
                    'Lettland',
                    'Liechtenstein',
                    'Litauen',
                    'Luxemburg',
                    'Madeira',
                    'Malta',
                    'Martinique',
                    'Mayotte',
                    'Melilla',
                    'Niederlande',
                    'Norwegen',
                    '\u00D6sterreich',
                    'Polen',
                    'Portugal',
                    'Rum\u00E4nien',
                    'Saint\u002DBarth\u00E9lemy',
                    'Saint\u002DMartin',
                    'Schweden',
                    'Slowakei',
                    'Slowenien',
                    'Spanien',
                    'Tschechien',
                    'Ungarn',
                    'Zypern'
                ],
                'Truschcan': [
                    'Andorra',
                    'F\u00E4r\u00F6er Inseln',
                    'Guernsey (GB)',
                    'Isle of Man (GB)',
                    'Jersey (GB)',
                    'Kanada',
                    'Monaco',
                    'Schweiz',
                    'T\u00FCrkei',
                    'USA'
                ],
                'RowPreferred': [
                    'Afghanistan',
                    '\u00C4gypten',
                    'Albanien',
                    'Algerien',
                    'Anguilla',
                    'Antigua \u0026 Barbuda',
                    'Argentinien',
                    'Armenien',
                    'Aruba',
                    'Aserbaidschan',
                    'Australien',
                    'Bahamas',
                    'Bahrain',
                    'Bangladesch',
                    'Barbados',
                    'Benin',
                    'Bermuda',
                    'Botswana',
                    'Brasilien',
                    'Britische Jungferninseln',
                    'Burkina Faso',
                    'Cayman Inseln',
                    'Chile',
                    'China',
                    'Costa Rica',
                    'Curacao (Niederl\u00E4ndische Antillen)',
                    'Demokratische Republik Kongo',
                    'Dominikanische Republik',
                    'Ecuador',
                    'El Salvador',
                    'Elfenbeink\u00FCste',
                    'Fidschi',
                    'Franz\u00F6sisch\u002DPolynesien',
                    'Gabun',
                    'Georgien',
                    'Guinea',
                    'Ghana',
                    'Gr\u00F6nland',
                    'Guatemala',
                    'Guyana',
                    'Honduras',
                    'Hongkong',
                    'Indien',
                    'Indonesien',
                    'Israel',
                    'Jamaika',
                    'Japan',
                    'Kambodscha',
                    'Kamerun',
                    'Kapverdische Inseln',
                    'Kasachstan',
                    'Katar',
                    'Kenia',
                    'Kolumbien',
                    'Kongo (Republik)',
                    'Lesotho',
                    'Liberia',
                    'Macau',
                    'Madagaskar',
                    'Malawi',
                    'Malaysia',
                    'Mali',
                    'Mauritius',
                    'Mexiko',
                    'Monaco',
                    'Mosambik',
                    'Nepal',
                    'Neuseeland',
                    'Nicaragua',
                    'Neuseeland',
                    'Nordmazedonien',
                    'Oman',
                    'Panama',
                    'Papua-Neuguinea',
                    'Paraguay',
                    'Peru',
                    'Philippinen',
                    'Russland',
                    'Saudi Arabien',
                    'Samoa',
                    'Senegal',
                    'Seychellen',
                    'Serbien',
                    'Singapur',
                    'St. Lucia',
                    'St. Vincent',
                    'S\u00FCdafrika',
                    'Zentralafrikanische Republik',
                    'S\u00FCdkorea',
                    'S\u00FCd-Sudan',
                    'Surinam',
                    'Taiwan',
                    'Tansania',
                    'Thailand',
                    'Togo',
                    'Tonga',
                    'Trinidad und Tobago',
                    'Turks \u0026 Caicosinseln',
                    'Tschad',
                    'Uganda',
                    'Ukraine',
                    'Uruguay',
                    'Vanuatu',
                    'Verein. Arab. Emirate',
                    'Vietnam'
                ],
                'Row2': [
                    'Angola',
                    '\u00C4quatorialguinea',
                    '\u00C4thiopien',
                    'Bhutan',
                    'Bolivien',
                    'Gabun',
                    'Gambia',
                    'Guam',
                    'Jemen',
                    'Laos',
                    'Libanon',
                    'Libyen',
                    'Mauretanien',
                    'Niederl\u00E4ndische Antillen',
                    'Salomon-Inseln',
                    'Simbabwe',
                    'Turkmenistan',
                    'Usbekistan',
                    'Venezuela'
                ],
                'WeltExp': [
                    'Kuba',
                    'Maritime Services',
                    'Namibia',
                    'Roaming im Flugzeug'
                ]
            },
            'toForeignCountries': {
                'Europe': [
                    'Belgien',
                    'Bulgarien',
                    'D\u00E4nemark',
                    'Estland',
                    'Finnland',
                    'Frankreich',
                    'Griechenland',
                    'Gro\u00DFbritannien',
                    'Irland',
                    'Island',
                    'Italien',
                    'Kroatien',
                    'Lettland',
                    'Liechtenstein',
                    'Litauen',
                    'Luxemburg',
                    'Malta',
                    'Niederlande',
                    'Norwegen',
                    '\u00D6sterreich',
                    'Polen',
                    'Portugal',
                    'Rum\u00E4nien',
                    'Schweden',
                    'Slowakei',
                    'Slowenien',
                    'Spanien',
                    'Tschechien',
                    'Ungarn',
                    'Zypern'
                ],
                'T\u00FCrkei': [
                    'T\u00FCrkei',
                ],
                'Welt': [
                    'Australien',
                    'Brasilien',
                    'Hongkong',
                    'Israel',
                    'Japan',
                    'Kanada',
                    'Kolumbien',
                    'Neuseeland',
                    'San Marino',
                    'Singapur',
                    'S\u00FCdkorea',
                    'Schweiz',
                    'USA'
                ]
            }
        };
    }

    var getCountryJSON = function () {
        // !IMPORTANT: check that you can cross domain origin
        //var countryAPI = 'https://eweb8.vfd2-testnet.de/meinvodafone/app/master_roaming.json';

        //return $.getJSON(countryAPI);

        //temporary solution until App-Developer master-json have finalized

        var Countries = {
            'countries': [
                {
                    'name': 'Afghanistan',
                    'code': 'AF'
                },
                {
                    'name': 'Anguilla',
                    'code': 'AI'
                },
                {
                    'name': 'Azoren',
                    'code': 'PT_20'
                },
                {
                    'name': 'Ceuta',
                    'code': 'ES_CE'
                },
                {
                    'name': 'Ecuador',
                    'code': 'EC'
                },
                {
                    'name': 'Kanarische Inseln',
                    'code': 'IC'
                },
                {
                    'name': 'Katar',
                    'code': 'QA'
                },
                {
                    'name': 'Madeira',
                    'code': 'PT_30'
                },
                {
                    'name': 'Martinique',
                    'code': 'MQ'
                },
                {
                    'name': 'Mayotte',
                    'code': 'YT'
                },
                {
                    'name': 'Melilla',
                    'code': 'ES_ML'
                },
                {
                    'name': 'Saint-Barthélemy',
                    'code': 'BL'
                },
                {
                    'name': 'Saint-Martin',
                    'code': 'MF'
                },
                {
                    'name': 'Nordmazedonien',
                    'code': 'MK'
                },
                {
                    'name': 'St. Lucia',
                    'code': 'LC'
                },
                {
                    'name': 'St. Vincent',
                    'code': 'VC'
                },
                {
                    'name': 'Andorra',
                    'code': 'AD'
                },
                {
                    'name': 'Angola',
                    'code': 'AO'
                },
                {
                    'name': 'Antigua &amp; Barbuda',
                    'code': 'AG'
                },
                {
                    'name': '&Auml;quatorialguinea',
                    'code': 'GQ'
                },
                {
                    'name': 'Argentinien',
                    'code': 'AR'
                },
                {
                    'name': 'Armenien',
                    'code': 'AM'
                },
                {
                    'name': 'Aruba',
                    'code': 'AW'
                },
                {
                    'name': 'Aserbaidschan',
                    'code': 'AZ'
                },
                {
                    'name': '&Auml;thiopien',
                    'code': 'ET'
                },
                {
                    'name': 'Australien',
                    'code': 'AU'
                },
                {
                    'name': 'Bahamas',
                    'code': 'BS'
                },
                {
                    'name': 'Bahrain',
                    'code': 'BH'
                },
                {
                    'name': 'Bangladesch',
                    'code': 'BD'
                },
                {
                    'name': 'Barbados',
                    'code': 'BB'
                },
                {
                    'name': 'Belgien',
                    'code': 'BE'
                },
                {
                    'name': 'Belize',
                    'code': 'BZ'
                },
                {
                    'name': 'Bermuda',
                    'code': 'BM'
                },
                {
                    'name': 'Bhutan',
                    'code': 'BT'
                },
                {
                    'name': 'Bolivien',
                    'code': 'BO '
                },
                {
                    'name': 'Bosnien-Herzegowina',
                    'code': 'BA'
                },
                {
                    'name': 'Botswana',
                    'code': 'BW'
                },
                {
                    'name': 'Brasilien',
                    'code': 'BR'
                },
                {
                    'name': 'Britische Jungferninseln',
                    'code': 'VG'
                },
                {
                    'name': 'Brunei',
                    'code': 'BN'
                },
                {
                    'name': 'Bulgarien',
                    'code': 'BG'
                },
                {
                    'name': 'Burkina Faso',
                    'code': 'BF'
                },
                {
                    'name': 'Burundi',
                    'code': 'BI'
                },
                {
                    'name': 'Cayman Inseln',
                    'code': 'KY'
                },
                {
                    'name': 'Chile',
                    'code': 'CL'
                },
                {
                    'name': 'China',
                    'code': 'CN'
                },
                {
                    'name': 'Costa Rica',
                    'code': 'CR'
                },
                {
                    'name': 'Curacao (Niederl&auml;ndische Antillen)',
                    'code': 'CW'
                },
                {
                    'name': 'D&auml;nemark',
                    'code': 'DK'
                },
                {
                    'name': 'Demokratische Republik Kongo',
                    'code': 'CD'
                },
                {
                    'name': 'Dominikanische Republik',
                    'code': 'DO'
                },
                {
                    'name': 'Dschibuti',
                    'code': 'DJ'
                },
                {
                    'name': '',
                    'code': 'EC'
                },
                {
                    'name': 'El Salvador',
                    'code': 'SV'
                },
                {
                    'name': 'Elfenbeink&uuml;ste',
                    'code': 'CI'
                },
                {
                    'name': 'Estland',
                    'code': 'EE'
                },
                {
                    'name': 'F&auml;r&ouml;er Inseln',
                    'code': 'FO'
                },
                {
                    'name': 'Fidschi',
                    'code': 'FJ'
                },
                {
                    'name': 'Finnland',
                    'code': 'FI'
                },
                {
                    'name': 'Frankreich',
                    'code': 'FR'
                },
                {
                    'name': 'Franz&ouml;sisch Guayana',
                    'code': 'GF'
                },
                {
                    'name': 'Franz&ouml;sisch Westindische Inseln',
                    'code': 'TF'
                },
                {
                    'name': 'Franz&ouml;sisch-Polynesien',
                    'code': 'PF'
                },
                {
                    'name': 'Gabun',
                    'code': 'GA'
                },
                {
                    'name': 'Gambia',
                    'code': 'GM'
                },
                {
                    'name': 'Georgien',
                    'code': 'GE'
                },
                {
                    'name': 'Ghana',
                    'code': 'GH'
                },
                {
                    'name': 'Gibraltar',
                    'code': 'GI'
                },
                {
                    'name': 'Griechenland',
                    'code': 'GR'
                },
                {
                    'name': 'Gr&ouml;nland',
                    'code': 'GL'
                },
                {
                    'name': 'Gro&szlig;britannien',
                    'code': 'GB'
                },
                {
                    'name': 'Guadeloupe',
                    'code': 'GP'
                },
                {
                    'name': 'Guam',
                    'code': 'GU'
                },
                {
                    'name': 'Guatemala',
                    'code': 'GT'
                },
                {
                    'name': 'Guernsey (GB)',
                    'code': 'GG'
                },
                {
                    'name': 'Guinea',
                    'code': 'GN'
                },
                {
                    'name': 'Guyana',
                    'code': 'GY'
                },
                {
                    'name': 'Honduras',
                    'code': 'HN'
                },
                {
                    'name': 'Hongkong',
                    'code': 'HK'
                },
                {
                    'name': 'Indien',
                    'code': 'IN'
                },
                {
                    'name': 'Indonesien',
                    'code': 'ID'
                },
                {
                    'name': 'Irak',
                    'code': 'IQ'
                },
                {
                    'name': 'Iran',
                    'code': 'IR'
                },
                {
                    'name': 'Irland',
                    'code': 'IE'
                },
                {
                    'name': 'Island',
                    'code': 'IS'
                },
                {
                    'name': 'Isle of Man (GB)',
                    'code': 'IM'
                },
                {
                    'name': 'Israel',
                    'code': 'IL'
                },
                {
                    'name': 'Italien',
                    'code': 'IT'
                },
                {
                    'name': 'Jamaika',
                    'code': 'JM'
                },
                {
                    'name': 'Japan',
                    'code': 'JP'
                },
                {
                    'name': 'Jemen',
                    'code': 'YE'
                },
                {
                    'name': 'Jersey (GB)',
                    'code': 'JE'
                },
                {
                    'name': 'Jordanien',
                    'code': 'JO'
                },
                {
                    'name': 'Kambodscha',
                    'code': 'KH'
                },
                {
                    'name': 'Kamerun',
                    'code': 'CM'
                },
                {
                    'name': 'Kanada',
                    'code': 'CA'
                },
                {
                    'name': 'Kapverdische Inseln',
                    'code': 'CV'
                },
                {
                    'name': 'Kasachstan',
                    'code': 'KZ'
                },
                {
                    'name': 'Kenia',
                    'code': 'KE'
                },
                {
                    'name': 'Kirgistan',
                    'code': 'KG'
                },
                {
                    'name': 'Kolumbien',
                    'code': 'CO'
                },
                {
                    'name': 'Kongo (Republik)',
                    'code': 'CG'
                },
                {
                    'name': 'Kosovo',
                    'code': 'XK'
                },
                {
                    'name': 'Kroatien',
                    'code': 'HR'
                },
                {
                    'name': 'Kuba',
                    'code': 'CU'
                },
                {
                    'name': 'Kuwait',
                    'code': 'KW'
                },
                {
                    'name': 'La Reunion',
                    'code': 'RE'
                },
                {
                    'name': 'Laos',
                    'code': 'LA'
                },
                {
                    'name': 'Lesotho',
                    'code': 'LS'
                },
                {
                    'name': 'Lettland',
                    'code': 'LV'
                },
                {
                    'name': 'Libanon',
                    'code': 'LB'
                },
                {
                    'name': 'Liberia',
                    'code': 'LR'
                },
                {
                    'name': 'Libyen',
                    'code': 'LY'
                },
                {
                    'name': 'Liechtenstein',
                    'code': 'LI'
                },
                {
                    'name': 'Litauen',
                    'code': 'LT',
                },
                {
                    'name': 'Luxemburg',
                    'code': 'LU'
                },
                {
                    'name': 'Macau',
                    'code': 'MO'
                },
                {
                    'name': 'Madagaskar',
                    'code': 'MG'
                },
                {
                    'name': 'Malawi',
                    'code': 'MW'
                },
                {
                    'name': 'Malaysia',
                    'code': 'MY'
                },
                {
                    'name': 'Malediven',
                    'code': 'MV'
                },
                {
                    'name': 'Mali',
                    'code': 'ML'
                },
                {
                    'name': 'Malta',
                    'code': 'MT'
                },
                {
                    'name': 'Marokko',
                    'code': 'MA'
                },
                {
                    'name': 'Mauretanien',
                    'code': 'MR'
                },
                {
                    'name': 'Mauritius',
                    'code': 'MU'
                },
                {
                    'name': 'Mexiko',
                    'code': 'MX'
                },
                {
                    'name': 'Moldawien',
                    'code': 'MD'
                },
                {
                    'name': 'Monaco',
                    'code': 'MC'
                },
                {
                    'name': 'Mongolei',
                    'code': 'MN'
                },
                {
                    'name': 'Montenegro',
                    'code': 'ME'
                },
                {
                    'name': 'Mosambik',
                    'code': 'MZ'
                },
                {
                    'name': 'Myanmar',
                    'code': 'MM'
                },
                {
                    'name': 'Namibia',
                    'code': 'NA'
                },
                {
                    'name': 'Nepal',
                    'code': 'NP'
                },
                {
                    'name': 'Neukaledonien',
                    'code': 'NC'
                },
                {
                    'name': 'Neuseeland',
                    'code': 'NZ'
                },
                {
                    'name': 'Nicaragua',
                    'code': 'NI'
                },
                {
                    'name': 'Niederlande',
                    'code': 'NL'
                },
                {
                    'name': 'Niederl&auml;ndische Antillen',
                    'code': 'AN'
                },
                {
                    'name': 'Niger',
                    'code': 'NE'
                },
                {
                    'name': 'Nigeria',
                    'code': 'NG'
                },
                {
                    'name': 'Norfolk Island',
                    'code': 'NF'
                },
                {
                    'name': 'Norwegen',
                    'code': 'NO'
                },
                {
                    'name': 'Oman',
                    'code': 'OM'
                },
                {
                    'name': '&Ouml;sterreich',
                    'code': 'AT'
                },
                {
                    'name': 'Osttimor',
                    'code': 'TL'
                },
                {
                    'name': 'Pakistan',
                    'code': 'PK'
                },
                {
                    'name': 'Pal&auml;stinensische Autonomiegebiete',
                    'code': 'PS'
                },
                {
                    'name': 'Panama',
                    'code': 'PA'
                },
                {
                    'name': 'Papua-Neuguinea',
                    'code': 'PG'
                },
                {
                    'name': 'Paraguay',
                    'code': 'PY'
                },
                {
                    'name': 'Peru',
                    'code': 'PE'
                },
                {
                    'name': 'Philippinen',
                    'code': 'PH'
                },
                {
                    'name': 'Polen',
                    'code': 'PL'
                },
                {
                    'name': 'Portugal',
                    'code': 'PT'
                },
                {
                    'name': 'Puerto Rico',
                    'code': 'PR'
                },
                {
                    'name': 'Ruanda',
                    'code': 'RW'
                },
                {
                    'name': 'Rum&auml;nien',
                    'code': 'RO'
                },
                {
                    'name': 'Russland',
                    'code': 'RU'
                },
                {
                    'name': 'Salomon-Inseln',
                    'code': 'SB'
                },
                {
                    'name': 'Sambia',
                    'code': 'ZM'
                },
                {
                    'name': 'Samoa',
                    'code': 'WS'
                },
                {
                    'name': 'Saudi Arabien',
                    'code': 'SA'
                },
                {
                    'name': 'Schweden',
                    'code': 'SE'
                },
                {
                    'name': 'Schweiz',
                    'code': 'CH'
                },
                {
                    'name': 'Senegal',
                    'code': 'SN'
                },
                {
                    'name': 'Serbien',
                    'code': 'RS'
                },
                {
                    'name': 'Seychellen',
                    'code': 'SC'
                },
                {
                    'name': 'Simbabwe',
                    'code': 'ZW'
                },
                {
                    'name': 'Singapur',
                    'code': 'SG'
                },
                {
                    'name': 'Slowakei',
                    'code': 'SK'
                },
                {
                    'name': 'Slowenien',
                    'code': 'SI'
                },
                {
                    'name': 'Spanien',
                    'code': 'ES'
                },
                {
                    'name': 'Sri Lanka',
                    'code': 'LK'
                },
                {
                    'name': 'S&uuml;dafrika',
                    'code': 'ZA'
                },
                {
                    'name': 'Zentralafrikanische Republik',
                    'code': 'ZAF'
                },
                {
                    'name': 'S&uuml;dkorea',
                    'code': 'KR'
                },
                {
                    'name': 'S&uuml;d-Sudan',
                    'code': 'SS'
                },
                {
                    'name': 'Surinam',
                    'code': 'SR'
                },
                {
                    'name': 'Swasiland',
                    'code': 'SZ'
                },
                {
                    'name': 'Tadschikistan',
                    'code': 'TJ'
                },
                {
                    'name': 'Taiwan',
                    'code': 'TW'
                },
                {
                    'name': 'Tansania',
                    'code': 'TZ'
                },
                {
                    'name': 'Thailand',
                    'code': 'TH'
                },
                {
                    'name': 'Togo',
                    'code': 'TG'
                },
                {
                    'name': 'Tonga',
                    'code': 'TO'
                },
                {
                    'name': 'Trinidad und Tobago',
                    'code': 'TT'
                },
                {
                    'name': 'Tschad',
                    'code': 'TD'
                },
                {
                    'name': 'Tschechien',
                    'code': 'CZ'
                },
                {
                    'name': 'Tunesien',
                    'code': 'TN'
                },
                {
                    'name': 'T&uuml;rkei',
                    'code': 'TR'
                },
                {
                    'name': 'Turkmenistan',
                    'code': 'TM'
                },
                {
                    'name': 'Turks &amp; Caicosinseln',
                    'code': 'TC'
                },
                {
                    'name': 'Uganda',
                    'code': 'UG'
                },
                {
                    'name': 'Ukraine',
                    'code': 'UA'
                },
                {
                    'name': 'Ungarn',
                    'code': 'HU'
                },
                {
                    'name': 'Uruguay',
                    'code': 'UY'
                },
                {
                    'name': 'USA',
                    'code': 'US'
                },
                {
                    'name': 'Usbekistan',
                    'code': 'UZ'
                },
                {
                    'name': 'Vanuatu',
                    'code': 'VU'
                },
                {
                    'name': 'Venezuela',
                    'code': 'VE'
                },
                {
                    'name': 'Verein. Arab. Emirate',
                    'code': 'AE'
                },
                {
                    'name': 'Vietnam',
                    'code': 'VN'
                },
                {
                    'name': 'Wei&szlig;russland',
                    'code': 'BY'
                },
                {
                    'name': 'Zypern',
                    'code': 'CY'
                }
            ]
        };

        return Countries;

    };




    var getCountryRow = function (country, destination) {
        var row = 'Other'; // Todo: maybe return "Other" to catch all countries not mentioned in the list

        if ('undefined' === typeof destination) {
            var destination = 'inForeignCountries';
        }

        for (var group in countryGroups[destination]) {
            if (countryGroups[destination].hasOwnProperty(group) && -1 !== $.inArray(country, countryGroups[destination][group])) {
                row = group;
                break;
            }
        }

        return row;
    };

    // returns the searched options from the defined flow
    var getFlowOption = function (destination, country, tariff) {
        if ('prepaid' !== contractMode) {
            return '';
        } else {
            // TODO: maybe a bit ugly, pls refactore this funtion, when you see this
            if (undefined !== prepaid[destination]) {
                if (undefined !== prepaid[destination][country]) {
                    if (undefined !== prepaid[destination][country][tariff]) {
                        return prepaid[destination][country][tariff];
                    }
                }
            }
        }

        return 'notFound';
    };

    var createRoamingStepper = function ($roamingStepper) {

        var $accordeon = $roamingStepper.find('.accordion .mod-toggle');

        var getDestination = function () {

            if ('' !== $accordeon.find('input[name=destination]:checked').val()) {
                return $accordeon.find('input[name=destination]:checked').val();
            }
            return '';
        };

        var getCountry = function () {

            if ('' !== $accordeon.find('input[name=country-destination]').val()) {
                return $accordeon.find('input[name=country-destination]').val();
            }
            return '';
        };

        var getTariff = function () {

            if ('' !== $accordeon.find('input[name=your-tariff]:checked').val()) {
                return $accordeon.find('input[name=your-tariff]:checked').val();
            }
            return '';
        };

        var getCurrentFlowOption = function () {
            var destination = getDestination();
            var country = getCountry();
            var tariff = getTariff();

            var option = getFlowOption(destination, country, tariff);
            if ('' !== option) {
                // first try was to find the specific country, when not found search the countryRow from the arrays
                if ('notFound' === option) {
                    option = getFlowOption(destination, getCountryRow(country, destination), tariff);
                }

                // when nothing found, try at least the "other" option
                if ('notFound' === option) {
                    option = getFlowOption(destination, 'other', tariff);
                }
            }

            return option;
        };

        var disableInput = function ($input) {
            $input.prop('disabled', 'disabled');
            $input.addClass('disabled');
        };

        var enableInput = function ($input) {
            $input.prop('disabled', false);
            $input.removeClass('disabled');
        };

        var clearInput = function ($input) {
            $input.val('');
            disableInput($input.parents('.tg-body').find('.next-step'));
        };

        var removeAccordeonChangeButton = function (step) {
            if ('all' === step) {
                $accordeon.find('.tg-item .tg-head .change-step').remove();
            } else {
                $($accordeon.find('.tg-item').get(step)).find('.tg-head .change-step').remove();
            }
        };

        var changeStepHeadline = function (step, additional) {
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');

            // remove just the old text, not the links and iconsDiese Preise gelten im EU-Ausland für Gespräche von CallYa ins Festnetz und in alle Mobilfunknetze.
            $tgHead.contents().filter(function () {
                return 3 === this.nodeType;
            }).remove();

            // remove old content if exists
            $tgHead.find('.item-headline').remove();
            // add custom headline
            $tgHead.append('<div class="item-headline"></div>');
            if ('' !== additional) {
                $tgHead.find('.item-headline').html('<strong>' + $tgHead.data('label') + ' </strong>' + additional);
            } else {
                $tgHead.find('.item-headline').html('<strong>' + $tgHead.data('label') + '</strong>');
            }
        };

        var resetStepHeadline = function (step) {
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');

            // remove just the old text, not the links and icons
            $tgHead.contents().filter(function () {
                return 3 === this.nodeType;
            }).remove();

            // remove old content if exists
            $tgHead.find('.item-headline').remove();
            // add custom headline
            $tgHead.append('<div class="item-headline"></div>');

            $tgHead.find('.item-headline').html($tgHead.data('label'));

        };

        // change logo to success, when all values are ok
        var changeLogoToSuccess = function (step) {
            var icon = '<svg class="i-xsml">' +
                '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-success-g-sml"></use>' +
                '</svg>';
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head .tg-icon');
            $tgHead.find('div, svg').remove();
            $tgHead.append(icon);
        };

        var changeLogoToNumber = function (step) {
            var icon = '<div>' +
                '<span class="icon-wrap">' +
                '<i class="i-step-number-b-xsml">' +
                '<span class="step-number-digit">' + (step + 1) + '</span>' +
                '</i>' +
                '</span></div>';

            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head .tg-icon');
            $tgHead.find('div, svg').remove();
            $tgHead.append(icon);
        };

        //override is for cases, if you clearly want to set this state (expects true)
        var changeLogoToNumberDisabled = function (step, override) {

            var inputValue = '';
            var $accordeonBody = $($accordeon.find('.tg-item').get(step)).find('.tg-body .tg-board');

            if (0 < $accordeonBody.find('input[type="radio"]:checked').length) {
                inputValue = $accordeonBody.find('input[type="radio"]:checked').next('label').text();
            }

            if (0 < $accordeonBody.find('input[type="text"]').length) {
                inputValue = $accordeonBody.find('input[type="text"]').val();
            }
            // just if no content is selected
            if ('' === inputValue || true === override) {
                var icon = '<div class="disabled">' +
                    '<span class="icon-wrap">' +
                    '<i class="i-step-number-b-xsml">' +
                    '<span class="step-number-digit">' + (step + 1) + '</span>' +
                    '</i>' +
                    '</span></div>';

                var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head .tg-icon');
                $($accordeon.find('.tg-item').get(step)).find('.tg-head').addClass('disabled');
                $tgHead.find('div, svg').remove();
                $tgHead.append(icon);
            }
        };

        var showFlowResult = function () {
            var destination = getDestination();
            var country = getCountry();
            var countryRow = getCountryRow(country, destination);
            var tariff = getTariff();
            var $tgBody = $($accordeon.find('.tg-item').get(3)).find('.tg-body .tg-board .module');
            $tgBody.empty();
            var results = $roamingStepper.find('.offers .offer-item-wrapper[data-country-row="' + country + '"][data-tariff="' + tariff + '"][data-destination="' + destination + '"]');
            if (undefined === results || 0 === results.length) {
                results = $roamingStepper.find('.offers .offer-item-wrapper[data-country-row="' + countryRow + '"][data-tariff="' + tariff + '"][data-destination="' + destination + '"]');
            }
            if (undefined === results || 0 === results.length) {
                results = $roamingStepper.find('.offers .offer-item-wrapper[data-country-row="other"][data-tariff="' + tariff + '"][data-destination="' + destination + '"]');
            }

            if (undefined === results || 0 === results.length) {
                results = '';
            }
            if ('' !== results) {
                $.each(results, function (index, result) {
                    $tgBody.append($(result).html());
                });
            } else {
                if (isRoamingSperre(country)) {
                    $tgBody.append($($roamingStepper.find('.offers .offer-item-wrapper[data-tariff="noRoamingNotification"][data-destination="' + destination + '"]')).html());
                } else {
                    $tgBody.append('<div>Leider wurde kein Angebot für Dich gefunden, bitte überprüf Deine Eingaben. Oder kontaktiere uns telefonisch.</div>');
                }
            }

        };

        var isRoamingSperre = function (country) {
            if ('osttimor' === country.toLowerCase() || 'belize' === country.toLowerCase() || 'burundi' === country.toLowerCase()
                || 'dschibuti' === country.toLowerCase() || 'malediven' === country.toLowerCase() || 'neukaledonien' === country.toLowerCase()
                || 'norfolk island' === country.toLowerCase() || 'swasiland' === country.toLowerCase()) {
                return true;
            } else {
                return false;
            }
        };

        var showFlowResultPrepaid = function () {
            var results = getCurrentFlowOption();
            var $tgBody = $($accordeon.find('.tg-item').get(3)).find('.tg-body .tg-board .module');
            $tgBody.empty();
            if ('notFound' !== results) {
                if ('inForeignCountries' === getDestination()) {
                    if (false === isNotFreeEU(results)) {
                        /*var imageTextHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-imageTextHeadline"]').data('value');
                        var imgSrc = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-imgSrc"]').data('value');
                        var imgSrcMobile = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-imgSrcMobile"]').data('value');
                        var textOnlyHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyHeadline"]').data('value');
                        var textOnlyText = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyText"]').data('value');
                        var textOnlyH5 = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH5"]').data('value');
                        var textOnlyH5Text = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH5Text"]').data('value');
                        var textOnlyH4b = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH4b"]').data('value');
                        var textOnlyH4bText = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH4bText"]').data('value');*/
                        //$tgBody.append(getImageText(imageTextHeadline, EURoamingText, '', imgSrc, imgSrcMobile));
                        //$tgBody.append(getTextOnlyFreeEUHTML(textOnlyHeadline, textOnlyText, textOnlyH5, textOnlyH5Text, textOnlyH4b, textOnlyH4bText, results.Link));
                        $tgBody.append(getTextOnlyHTML('', 'Diese Preise gelten im EU-Ausland für Gespräche von CallYa ins Festnetz und in alle Mobilfunknetze. Es gilt eine Taktung von 30/1, wenn nichts anderes in der Tabelle angegeben ist.'));
                        $tgBody.append(getTableHTML(
                            {
                                tableHeadline: results.TableHeadline,
                                outgoingMobile: results.OutgoingCallMobile,
                                outgoingHome: results.OutgoingCallHome,
                                incomingMobile: results.IncomingCallMobile,
                                incomingHome: results.IncomingCallHome,
                                sms: results.SMS,
                                mms: results.MMS,
                                data: results.Data
                            })
                        );
                        vf['responsive-table'].init();
                        $tgBody.append(getTextOnlyHTML('', '<b>' + results.EURoamingText + '</b>'));
                        $tgBody.append(getButtonHTML(results.ButtonText, results.ButtonColor, results.Link));
                    } else {
                        /*var topHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="not-free-eu-topHeadline"]').data('value');
                        var topText = $roamingStepper.find('.stepper-prepaid-value[data-key="not-free-eu-topText"]').data('value');
                        var bottomHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="not-free-eu-bottomHeadline"]').data('value');

                        var footnote = results.footnote !== undefined ? results.footnote : topText;
                        var UpsellTariff = results.UpsellTariff !== undefined ? results.UpsellTariff : '';*/
                        //$tgBody.append(getTextOnlyHTML(topHeadline, footnote, ''));
                        $tgBody.append(getTextOnlyHTML('', 'Diese Preise gelten im EU-Ausland für Gespräche von CallYa ins Festnetz und in alle Mobilfunknetze. Es gilt eine Taktung von 30/1, wenn nichts anderes in der Tabelle angegeben ist.'));
                        $tgBody.append(getTableHTML(
                            {
                                tableHeadline: results.TableHeadline,
                                outgoingMobile: results.OutgoingCallMobile,
                                outgoingHome: results.OutgoingCallHome,
                                incomingMobile: results.IncomingCallMobile,
                                incomingHome: results.IncomingCallHome,
                                sms: results.SMS,
                                mms: results.MMS,
                                data: results.Data
                            })
                        );
                        vf['responsive-table'].init();
                        $tgBody.append(getTextOnlyHTML('', '<b>' + results.EURoamingText + '</b>'));
                        $tgBody.append(getButtonHTML(results.ButtonText, results.ButtonColor, results.Link));
                    }
                } else if ('toForeignCountries' === getDestination()) {
                    $tgBody.append(getTableHTML(
                        {
                            tableHeadline: results.TableHeadline,
                            outgoingMobile: results.OutgoingCallMobile,
                            outgoingHome: results.OutgoingCallHome,
                            incomingMobile: results.IncomingCallMobile,
                            incomingHome: results.IncomingCallHome,
                            sms: results.SMS,
                            mms: results.MMS,
                            data: results.Data
                        })
                    );
                    vf['responsive-table'].init();
                    $tgBody.append(getFootnoteHTML(results.Footnote));
                    vf.footnotes.init('');
                    $tgBody.find('.accordion .mod-toggle').off('click');
                    $tgBody.find('.accordion .mod-toggle a').trigger('click', false);
                }
            } else {
                $tgBody.append('<div>Leider wurde kein Angebot für Sie gefunden, bitte überprüfen Sie Ihre Eingaben. Oder kontakieren Sie uns telefonisch.</div>');
            }
        };

        var setCountryInputState = function ($input) {
            if ('prepaid' !== contractMode) {
                var countryRow = getCountryRow($input.val(), getDestination()); // Todo: not sure, if getDestination() is working as expected here
            } else {
                var countryRow = getRawCountryList()[$input.val()];
            }
            if ('' !== countryRow && undefined !== countryRow) {
                enableInput($input.parents('.tg-body').find('.next-step'));
            } else {
                disableInput($input.parents('.tg-body').find('.next-step'));
                changeLogoToNumberDisabled(2, true);
            }
        };

        var enableAutoComplete = function ($input, json) {
            var options = {
                data: json,
                getValue: 'name',
                list: {
                    match: {
                        enabled: true,
                        method: function (element, phrase) {
                            if (0 === element.search(phrase)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                    },
                    maxNumberOfElements: 8,
                    onSelectItemEvent: function () {
                        setCountryInputState($input);
                    }
                },
                template: {
                    type: 'custom',
                    method: function (value, item) {
                        var result = '';
                        var additionalClass = 'no-flag';
                        if ('' !== item.code) {

                            result = '<img src="http://media.vodafone.de/www/images/app/' + (item.code).toLowerCase() + '_flag.png" width="19px" class="flag">';

                            additionalClass = '';
                        }
                        return result + '<span class="' + additionalClass + '">' + value + '</span>';
                    }
                },

                theme: 'square'
            };

            $input.easyAutocomplete(options);
        };

        var normalizeCountries = function (countries) {
            var normalized = countries;

            $.each(normalized, function (index, value) {
                normalized[index].name = value.name.replace('&Auml;', '\u00C4');
                normalized[index].name = value.name.replace('&auml;', '\u00E4');
                normalized[index].name = value.name.replace('&Ouml;', '\u00D6');
                normalized[index].name = value.name.replace('&ouml;', '\u00F6');
                normalized[index].name = value.name.replace('&Uuml;', '\u00DC');
                normalized[index].name = value.name.replace('&uuml;', '\u00FC');
                normalized[index].name = value.name.replace('&amp;', '\u0026');
                normalized[index].name = value.name.replace('&szlig;', '\u00DF');
            });

            normalized.push({
                alternateCode: '',
                code: '',
                name: 'Maritime Services'
            });

            normalized.push({
                alternateCode: '',
                code: '',
                name: 'Roaming im Flugzeug'
            });

            return normalized;
        };

        var jsonCountryProxy = function () {

            var $input = $roamingStepper.find('.roaming-stepper-item input[name=country-destination]');
            if ('prepaid' !== contractMode) {
                var jsonRequest = getCountryJSON();
                /*jsonRequest.done(function (items) {
                    var countries = normalizeCountries(items.countries);
                    enableInput($input);
                    enableAutoComplete($input, countries);
                });*/
                var countries = normalizeCountries(jsonRequest.countries);
                enableInput($input);
                enableAutoComplete($input, countries);
            } else {
                var countries = getCountryList(getDestination());
                enableInput($input);
                enableAutoComplete($input, countries);
            }
        };

        var sendingTracking = function (step) {

            //var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');
            //var stepName = $tgHead.data('label');

            var stepStatus = '';

            if (0 === step) {
                stepStatus = ['start'];
            }
            if (3 === step) {
                stepStatus = ['end', 'success'];
            }
            // the stepname provided by adversitement
            var providedStepName = '';
            if (0 === step) {
                providedStepName = 'dein ziel';
            } else if (1 === step) {
                providedStepName = 'dein land';
            } else if (2 === step) {
                providedStepName = 'deinen aktuellen tarif';
            } else if (3 === step) {
                providedStepName = 'deinen optionen';
            }

            // step + 1, because in tracking the first number is 1 instead of 0
            var trackingValues = [
                'pageview', {
                    'siteArea': 'mobility',
                    'platformType': 'responsive',
                    'loginStatus': 'not logged in',
                    'siteStructure': [
                        'privatkunden',
                        'roamingberater',
                        providedStepName
                    ],
                    'pageType': 'roamingberater',
                    'domain': 'eweb8.vfd2-testnet.de',
                    'oForm': {
                        'name': 'roamingberater',
                        'formType': 'roamingberater',
                        'formStep': step + 1,
                        'formStepName': providedStepName,
                        'formStatus': stepStatus
                    }
                }
            ];

            if (0 < step) {
                var $accordeonBody = $($accordeon.find('.tg-item').get(0)).find('.tg-body .tg-board');
                trackingValues[1].roamingGoal = $accordeonBody.find('input[type="radio"]:checked').next('label').text();
            }
            if (1 < step) {
                var $accordeonBody = $($accordeon.find('.tg-item').get(1)).find('.tg-body .tg-board');
                trackingValues[1].roamingCountry = $accordeonBody.find('input[type="text"]').val();
            }
            if (2 < step) {
                var $accordeonBody = $($accordeon.find('.tg-item').get(2)).find('.tg-body .tg-board');
                trackingValues[1].roamingTariff = $accordeonBody.find('input[type="radio"]:checked').next('label').text();
            }
            window._ddq = window._ddq || [];
            _ddq.push(trackingValues);

        };

        var closeAllSteps = function () {
            $accordeon.find('.tg-item .tg-body').hide();
        };

        var setPrepaidTariffOptions = function () {
            var options = $accordeon.find('input[name="your-tariff"]');
            if (undefined !== prepaid[getDestination()]) {
                if (undefined !== prepaid[getDestination()][getCountry()]) {
                    options.each(function (key, input) {
                        var $input = $(input);
                        if (undefined !== prepaid[getDestination()][getCountry()][$input.val()]) {
                            $input.parent().show();
                        } else {
                            $input.parent().hide();
                        }
                    });
                }
            }
        };

        var goToStep = function (step) {
            if ('prepaid' === contractMode) {
                jsonCountryProxy();
                setPrepaidTariffOptions();
            }

            $accordeon.find('.tg-head').removeClass('disabled');
            $accordeon.find('.tg-head').removeClass('tg-active');
            $($accordeon.find('.tg-item').get(step)).find('.tg-head').addClass('tg-active');
            $accordeon.find('.tg-item').each(function (index) {
                // previous steps and the last one
                if (index < step || 3 === step) {
                    changeLogoToSuccess(index);
                } else if (index === step) {
                    changeLogoToNumber(index);
                } else {
                    changeLogoToNumberDisabled(index);
                }
            });
            closeAllSteps();
            $($accordeon.find('.tg-item').get(step)).find('.tg-body').show();
            // last step, show the results
            if (3 === step) {
                if ('prepaid' !== contractMode) {
                    showFlowResult();
                } else {
                    showFlowResultPrepaid();
                }
            }
        };

        var changeStep = function () {
            // go to step of the clicked item
            if (false === $(this).parents('.tg-head').hasClass('disabled')) {
                goToStep($(this).parents('.tg-item').index());
            }
        };

        var addAccordeonChangeButton = function (step) {
            var button = '<div class="change-step">\u00E4ndern</div>';
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');
            // in case some change button is already setted
            $tgHead.find('.change-step').remove();
            $tgHead.append(button);
            $tgHead.find('.change-step').on('click', changeStep);
        };

        var nextStep = function (event) {

            var step = event.data.param;

            console.log(step);

            var additionalText = '';
            var $accordeonBody = $(this).parents('.tg-item').find('.tg-body .tg-board');
            if (0 < $accordeonBody.find('input[type="radio"]:checked').length) {
                additionalText = $accordeonBody.find('input[type="radio"]:checked').next('label').html();
            }

            if (0 < $accordeonBody.find('input[type="text"]').length) {
                additionalText = $accordeonBody.find('input[type="text"]').val();
            }

            if (0 <= step) {
                if (isRoamingSperre(getCountry()) && 1 === step) {
                    goToStep(3);
                } else {
                    goToStep(step + 1);
                }
                addAccordeonChangeButton(step);
                changeStepHeadline(step, additionalText);
                sendingTracking(step + 1);
                // welcome to the last step, set this as done
                if (3 === (step + 1)) {
                    changeStepHeadline(step + 1, '');
                }
            } else {
                var actualyIndex = $(this).parents('.tg-item').index();

                addAccordeonChangeButton(actualyIndex);
                changeStepHeadline(actualyIndex, additionalText);
                sendingTracking(actualyIndex + 1);
                // welcome to the last step, set this as done
                if (3 === (actualyIndex + 1)) {
                    changeStepHeadline(actualyIndex + 1, '');
                }
            }
        };

        var clearAllSteps = function () {
            removeAccordeonChangeButton('all');
            // clear all inputs
            $roamingStepper.find('input,label').removeClass('checked');
            $roamingStepper.find('input:not([type="radio"])').val('');
            $roamingStepper.find('input[type="radio"]').attr('checked', false);

            $roamingStepper.find('.next-step').each(function () {
                disableInput($(this));
            });
            $roamingStepper.find('.tg-item').each(function (index) {
                resetStepHeadline(index);
            });
            // go to first step
            goToStep(0);

            var $scrollToElement = $($accordeon.find('.tg-item').get(0)).find('.tg-head');
            $('html, body').animate({
                scrollTop: $scrollToElement.offset().top
            }, 200);
        };

        var disableAccordeonOnClickOpen = function () {
            $accordeon.off('click');
            $accordeon.find('a').trigger('click', false);
        };

        var addNextButton = function (step) {
            var button = '<button class="next-step disabled btn btn-sml" disabled="disabled">Zum n\u00E4chsten Schritt</button>';
            var $tgBody = $($accordeon.find('.tg-item').get(step)).find('.tg-body');
            $tgBody.append(button);
            $tgBody.find('.next-step').on('click', { param: step }, nextStep);
        };

        var addResetButton = function () {
            var button = '<button class="reset-steps btn btn-sml btn-alt">Zur\u00FCcksetzen</button>';
            var $tgBody = $($accordeon.find('.tg-item').get(3)).find('.tg-body');
            $tgBody.append(button);
            $tgBody.find('.reset-steps').on('click', clearAllSteps);
        };

        $roamingStepper.find('.delete-content').on('click', function () {
            clearInput($(this).parent().find('input'));
        });

        $roamingStepper.find('.change-step').on('click', changeStep);

        $roamingStepper.find('input[type="radio"]').on('click', function () {
            enableInput($(this).parents('.tg-body').find('.next-step'));
        });

        $roamingStepper.find('input[type="text"]').on('change', function () {
            setCountryInputState($(this));
        });

        var init = function () {
            disableAccordeonOnClickOpen();

            goToStep(0);
            jsonCountryProxy();
            addNextButton(0);
            addNextButton(1);
            addNextButton(2);
            addResetButton();
            //initial Tracking
            sendingTracking(0);
        };
        init();
    };

    var init = function () {
        $(settings.target).each(function () {
            createRoamingStepper($(this));
        });
    };


    vf['roaming-stepper'] = {
        settings: settings,
        init: init
    };
}(vf));
